<template>
  <div class="status-container " :class="cssClass">
    <span class="status-container--text text">{{ text }}</span>
  </div>
</template>

<script>
export default {
  name: "statusBadge",
  props: {
    status: null,
  },

  computed: {
    text() {
      switch (this.status) {
        case 1:
          return this.$t('status.pending');
        case 2:
          return this.$t('status.preparing');
        case 3:
          return this.$t('status.delivering');
        case 4:
          return this.$t('status.complete');
        case 5:
          return this.$t('status.rejected');
        case 6:
          return this.$t('status.canceled');
        default:
          return this.$t('status.other');
      }
    },

    cssClass() {
      switch (this.status) {
        case 1:
          return 'yellow';
        case 2:
          return 'blue';
        case 3:
          return 'purple';
        case 4:
          return 'green';
        case 5:
          return 'red';
        case 6:
          return'red';
        default:
          return 'other'
      }
    },


  }
}
</script>


<template>
  <div class='wameed-dashboard-page-content'>
    <page-header
      :title="$t('orders.title')"
      :btn-title="$t('orders.new_btn')"
      :route-name="newOrderRoute"
    />


    <div>
      <filter-header
        :content='content'
        @applyFilter='applyFilter'
        @resetFilter='resetFilter'
        @orderApplicants='orderApplicants'
      />
    </div>
    <section
      class='
        wameed-dashboard-page-content_body
        d-flex
        flex-column
        justify-content-between
        overflow-hidden
      '
    >
      <div class='d-flex flex-wrap flex-1'>

        <b-col lg='12' md='12' class='px-0'>
          <div class='w-table d-flex flex-column'>
            <w-tables
              :noDataTitle="$t('table.no_data_title')"
              :noDataSubtitle="$t('table.no_data_subtitle')"
              v-if='getData'
              :per-page='filterData.per_page'
              :page-number='filterData.page'
              :items='getData'
              :fields='fields'
              :custimized-items="[
                { name: 'date' },
                { name: 'vendor' },
                { name: 'service_type' },
                { name: 'action' },
                { name: 'price' },
                { name: 'status' },
                { name: 'rate' },
              ]"
            >

              <template slot='date' slot-scope='{ data }'>
                <div class='d-flex'>
                  <span class='text-font-main mx-3'>{{ formatDate(data.item.date).replace('-', '\n') }}</span>
                </div>
              </template>

              <template slot='vendor' slot-scope='{ data }'>
                <check-value :value='data.item.vendor' />
              </template>

              <template slot='service_type' slot-scope='{ data }'>
                <check-value :value='data.item.service_type' />
              </template>


              <template slot='price' slot-scope='{ data }'>
                <check-price
                  :price='Number(data.item.price)+Number(data.item.service_fee)'
                  :dis-price='Number(data.item.price)+Number(data.item.service_fee)-Number(data.item.dis_price)'
                />
              </template>

              <template slot='rate' slot-scope='{ data }'>
                <customer-rate :rate='data.item.rate' />
              </template>

              <template slot='status' slot-scope='{ data }'>
                <custom-badge :text='data.item.status_name' :color='data.item.status_color' />
              </template>


              <template slot='action' slot-scope='{ data }'>
                <b-dropdown
                  variant='background'
                  class='table-option'
                  toggle-class='text-decoration-none rounded-10 px-2 py-2 '
                  menu-class='rounded-8'
                  no-caret
                  dropleft
                >
                  <template v-slot:button-content>
                    <vertical-dots-icon />
                  </template>

                  <b-dropdown-item
                    :to='toToDetail(data.item.id)'
                  >
                    <span class='text-regular-14 text-font-secondary permission-show-detail'>
                      <eyeon-icon class='mx-2' />
                      {{ $t('common.show_details') }}
                    </span>
                  </b-dropdown-item>


                </b-dropdown>
              </template>


            </w-tables>
          </div>
        </b-col>

        <b-col lg='12' md='12' class='px-0'>
          <wameed-pagination
            v-if='getTotalData'
            v-model='filterData.page'
            :page='filterData.page'
            :total-items='getTotalData.totalItems'
            :per_page='filterData.per_page'
            :menu-title="$t('common.show_results')"
            @changePage='changePage'
            @changeCurrentPage='changeCurrentPage'
          />
        </b-col>
      </div>
    </section>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import PageHeader from '@/components/wameed/WameedPageHeader.vue';
import FilterHeader from '@/components/wameed/WameedFilterHeader.vue';

import { WameedPagination, WameedTables as WTables } from 'wameed-ui/dist/wameed-ui.esm';
import CheckPrice from '@/components/checkPrice';
import CustomerRate from '@/components/customerRate';
import StatusBadge from '@/components/statusBadge';
import CustomBadge from '@/components/customBadge';
import CheckValue from '@/components/checkValue';

export default {
  components: {
    CheckValue,
    CustomBadge,
    StatusBadge,
    CustomerRate,
    CheckPrice,
    WameedPagination,
    PageHeader,
    FilterHeader,
    WTables
  },
  data() {
    return {
      filterData: {
        status: '',
        year: '',
        month: '',
        page: 1,
        per_page: 20,
        order_by: 'desc',
        search: ''
      },
      content: [

        {
          title: this.$i18n.t('orders.table.status'),
          key: 'status',
          id: 'id',
          name: 'title',
          selectedData: [],
          type: 'text',
          translate: true,
          data: [
            {
              id: '-1',
              title: this.$i18n.t('status.pending')
            },
            {
              id: 1,
              title: this.$i18n.t('status.preparing')
            },
            {
              id: 2,
              title: this.$i18n.t('status.prepared')
            },
            {
              id: 3,
              title: this.$i18n.t('status.delivering')
            },
            {
              id: 4,
              title: this.$i18n.t('status.complete')
            },
            {
              id: 5,
              title: this.$i18n.t('status.waiting')
            },
            {
              id: 6,
              title: this.$i18n.t('status.confirmation')
            },
            {
              id: 7,
              title: this.$i18n.t('status.canceled')
            },
            {
              id: 9,
              title: this.$i18n.t('status.returned')
            },
            {
              id: 10,
              title: this.$i18n.t('status.assign_driver')
            },
            {
              id: 11,
              title: this.$i18n.t('status.scheduled')
            },

          ]
        },
        {
          title: this.$i18n.t('orders.table.date'),
          key: 'Date',
          id: 'id',
          name: 'title',
          selectedData: [],
          translate: true,
          type: 'date',
          startDate: null,
          endDate: null
        },
        {
          title: this.$i18n.t('filters.stars'),
          key: 'stars',
          id: 'id',
          name: 'title',
          selectedData: [],
          type: 'stars',
          data: [

            {
              id: 1,
              title: 1
            },
            {
              id: 2,
              title: 2
            },
            {
              id: 3,
              title: 3
            },
            {
              id: 4,
              title: 4
            },
            {
              id: 5,
              title: 5
            }
          ]
        }
      ],
      fields: [
        {
          key: 'index',
          label: '#',
          sortable: true
        },

        {
          key: 'no',
          label: this.$i18n.t('orders.table.no'),
          sortable: true
        },

        {
          key: 'user_name',
          label: this.$i18n.t('orders.table.user_name'),
          sortable: true
        },
        {
          key: 'user_complete_orders',
          label: this.$i18n.t('orders.table.user_complete_orders'),
          sortable: true
        },
        {
          key: 'driver_name',
          label: this.$i18n.t('orders.table.driver_name'),
          sortable: true
        },

        {
          key: 'date',
          label: this.$i18n.t('orders.table.date'),
          sortable: true,
          tdClass: 'w-120px'
        },
        {
          key: 'vendor',
          label: this.$i18n.t('orders.table.place'),
          sortable: true
        },
        {
          key: 'service_type',
          label: this.$i18n.t('orders.table.type'),
          sortable: true
        },
        {
          key: 'price',
          label: this.$i18n.t('table.price'),
          sortable: true
        },
        {
          key: 'status',
          label: this.$i18n.t('orders.table.status'),
          sortable: true
        },
        {
          key: 'rate',
          label: this.$i18n.t('orders.table.rate'),
          sortable: true
        },

        {
          key: 'action',
          label: this.$i18n.t('table.options')
        }
      ]

    };
  },
  //watch if the route changes
  watch: {
    $route(to, from) {
      this.loadData();
    }
  },
  computed: {
    ...mapGetters({
      getData: 'vendor/orders/getData',
      getTotalData: 'vendor/orders/getTotal',
    }),

    newOrderRoute() {
      return this.$route.meta.type + '-order-new';
    }
  },

  async created() {
    this.loadData();
  },

  methods: {
    ...mapActions({
      _loadData: 'vendor/orders/get',
    }),
    changeCurrentPage(item) {
      this.filterData = {
        ...this.filterData,
        per_page: item.id,
        page: 1
      };
      this.loadData();
    },
    changePage(event) {
      this.filterData = {
        ...this.filterData,
        page: event
      };
      this.loadData();
    },
    loadData() {
      var serviceTypes = this.$route.meta.type;
      this._loadData({ service_type: serviceTypes, ...this.filterData });
    },
    toToDetail(id) {
      return {
        name: this.$route.meta.type + '-order-detail',
        params: { lang: this.$i18n.locale, id }
      };
    },
    newOrderRouteName() {
      return this.$route.meta.type + '-order-new';
    },
    applyFilter() {
      this.content.forEach(item => {
        if (item.key === 'Date') {
          if (item.startDate)
            this.filterData['startDate'] = item.startDate.toString();
          if (item.endDate)
            this.filterData['endDate'] = item.endDate.toString();
        } else {
          this.filterData[item.key] = item.selectedData.toString();
        }
      });
      this.loadData();
    },
    resetFilter() {
      this.content.forEach((item, i) => {
        this.content[i].selectedData = [];
        this.filterData[item.key] = item.selectedData.toString();
      });
      this.loadData();
    },
    orderApplicants(item) {
      this.filterData = {
        ...this.filterData,
        ...item
      };
      this.loadData();
    }
  }
};
</script>
